import Image from "next/image";
import { cn } from "@/lib/utils";

export function SiteLogo({
  className,
  logo,
}: {
  className?: string;
  logo?: any;
}) {
  return (
    <div className="relative h-10 w-[50px] sm:h-14 sm:w-[70px]">
      {logo.source_url ? (
        <Image
          alt=""
          className={cn("h-10 w-auto flex-shrink-0 sm:h-14", className)}
          fill
          priority
          quality={100}
          sizes="(min-width: 640px) 70px, 50px"
          src={logo.source_url}
        />
      ) : null}
    </div>
  );
}
