"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";

export function TertiaryMenu({ tertiaryItems, page_slug_to_hide_navbar }) {
  const pathname = usePathname();

  const hideNavBar = page_slug_to_hide_navbar.some(
    (page) => page.slug === pathname,
  );

  return (
    <div className="bg-primary py-2 text-white">
      <nav className="edges flex items-center justify-end">
        {tertiaryItems.map((menuItem) => {
          if (hideNavBar) {
            return;
          }
          return (
            <Link
              className="ml-2 text-[15px] text-white"
              dangerouslySetInnerHTML={{ __html: menuItem.label || "" }}
              href={menuItem.path || ""}
              key={menuItem.id}
            />
          );
        })}
      </nav>
    </div>
  );
}
