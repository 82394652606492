import Link from "next/link";
import React from "react";
import { cn } from "@/lib/utils";

export const MenuItemLink = React.forwardRef(
  (
    {
      label = "",
      path = "#",
      className,
      description,
    }: {
      label: string;
      path: string;
      className?: string;
      description?: string;
    },
    ref: React.Ref<HTMLAnchorElement>,
  ) => {
    return (
      <Link
        className={cn(
          "group block p-2 hover:bg-white hover:text-primary focus:bg-white focus:text-primary ",
          className,
        )}
        href={path}
        ref={ref}
      >
        {label ? (
          <span
            className="block text-base font-medium"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : null}

        {description ? (
          <span
            className="mt-1 block text-[14px] text-[#CCCCCC] group-hover:text-primary"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : null}
      </Link>
    );
  },
);

MenuItemLink.displayName = "MenuItemLink";
